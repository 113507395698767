@font-face {
    font-family: 'Open Sans';
    src: url('static/Fonts/OpenSans-Light.woff') format('woff2');
}

body {
    margin: 0;
    font-family: 'Open Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* position: relative; */
}

html, body {
    overflow-x: hidden;
}